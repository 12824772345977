<template>
  <b-modal
    v-model="isBlockCardModalActive"
    has-modal-card
    trap-focus
    class="px-5"
    @close="$emit('close')"
  >
    <template #default>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Feuerlöscher sperren</p>
        </header>
        <section class="modal-card-body">
          <p>
            Wenn Du Deinen Feuerlöscher verloren hast, kannst Du diesen hier
            sperren.
          </p>
          <p class="mt-2">
            <b-checkbox v-model="noUnblock" class="mb-4">
              Ich habe zur Kenntnis genommen, dass ich meinen Feuerlöscher nicht
              alleine entsperren kann.
            </b-checkbox>
          </p>
          <p>
            <b-checkbox v-model="iAmSure" class="mb-4">
              Ich bin mir sicher, dass ich meinen Feuerlöscher sperren möchte.
            </b-checkbox>
          </p>
        </section>
        <footer class="modal-card-foot">
          <b-button
            :loading="isLoading"
            label="Karte sperren"
            type="is-primary"
            @click="$emit('blockCard')"
            :disabled="!noUnblock || !iAmSure"
          ></b-button>
          <b-button
            :loading="isLoading"
            label="Abbrechen"
            type="is-light"
            @click="$emit('close')"
          ></b-button>
        </footer>
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: "BlockCardModal",
  components: {},
  data() {
    return {
      noUnblock: false,
      iAmSure: false,
    };
  },
  props: {
    isBlockCardModalActive: {
      type: Boolean,
      default: false,
    },
    cardCode: {
      type: String,
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {},
};
</script>
