<template>
  <div>
    <div class="container">
      <b-message
        type="is-info"
        v-if="notActivated"
        title="Diese Karte wurde noch nicht aktiviert."
        >Um Deinen Feuerlöscher verwenden zu können, muss diese zunächst
        aktiviert werden.</b-message
      >
      <div class="card">
        <div class="card-content">
          <p v-if="card != null">
            Aufgeladenes Guthaben:
            <strong>{{ Number(card.balance).toFixed(2) }} &euro;</strong>
          </p>
          <p>Guthaben-Nr.: {{ cardCode }}</p>
          <p v-if="card != null" class="mt-2">
            <span v-if="card.account_id == null">
              <b-icon
                pack="fas"
                icon="user-xmark"
                type="is-danger"
              />&nbsp;Diese Karte wurde mit keinem Kundenkonto verknüpft.
            </span>
          </p>
          <div class="columns is-multiline is-mobile mt-3">
            <div class="column is-half" v-if="card">
              <b-button
                :disabled="isLoading"
                expanded
                icon-pack="fas"
                icon-left="credit-card"
                @click="rechargeCard"
                >Aufladen</b-button
              >
            </div>
            <div class="column is-half" v-if="card && card.account_id == null">
              <b-button
                expanded
                icon-pack="fas"
                icon-left="user-plus"
                @click="openLinkCardModal"
                >Verknüpfen</b-button
              >
            </div>
            <div class="column is-half" v-if="card">
              <b-button
                :disabled="isLoading"
                expanded
                icon-pack="fas"
                icon-left="shopping-cart"
                @click="navigateToTransactions"
                >Buchungen</b-button
              >
            </div>
            <div class="column is-half" v-if="card && card.account_id != null">
              <b-button
                @click="isBlockCardModalActive = true"
                expanded
                icon-pack="fas"
                icon-left="lock"
                >Sperren</b-button
              >
            </div>
            <div class="column is-half" v-if="card">
              <b-button
                expanded
                icon-pack="fas"
                icon-left="qrcode"
                @click="isQRCodeModalActive = true"
                >Anzeigen</b-button
              >
            </div>
            <div class="column is-full" v-if="notActivated">
              <b-button
                @click="activateCard"
                :loading="isLoading"
                expanded
                icon-pack="fas"
                icon-left="check-circle"
                >Aktivieren</b-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <login-modal
      :isLoginModalActive="isLoginModalActive"
      :contentText="loginModalContentText"
      :redirectUrl="loginModalRedirectUrl"
      @close="isLoginModalActive = false"
    ></login-modal>
    <qr-code-modal
      :isQRCodeModalActive="isQRCodeModalActive"
      :qrCodeData="qrCodeData"
      @close="isQRCodeModalActive = false"
    ></qr-code-modal>
    <link-card-modal
      :cardCode="cardCode"
      :isLinkCardModalActive="isLinkCardModalActive"
      @close="isLinkCardModalActive = false"
      @linkCard="linkCard"
      :isLoading="isLoading"
    ></link-card-modal>
    <block-card-modal
      :cardCode="cardCode"
      :isBlockCardModalActive="isBlockCardModalActive"
      @close="isBlockCardModalActive = false"
      @blockCard="blockCard"
      :isLoading="isLoading"
    ></block-card-modal>
  </div>
</template>

<script>
import LinkCardModal from "./modal/LinkCardModal.vue";
import LoginModal from "./modal/LoginModal.vue";
import QrCodeModal from "./modal/QrCodeModal.vue";
import BlockCardModal from "./modal/BlockCardModal.vue";

export default {
  name: "Home",
  components: {
    LoginModal,
    QrCodeModal,
    LinkCardModal,
    BlockCardModal,
  },
  data() {
    return {
      isLoading: false,
      card: null,
      notActivated: false,
      isQRCodeModalActive: false,
      isLoginModalActive: false,
      isLinkCardModalActive: false,
      isBlockCardModalActive: false,
      loginModalContentText: "",
      loginModalRedirectUrl: null,
      qrCodeData: "https://feuerlöscher.digital/card/" + this.cardCode,
    };
  },
  props: {
    cardCode: {
      type: String,
      default: null,
    },
  },
  methods: {
    init() {
      this.loadCardDetails();
    },
    navigateToTransactions() {
      this.$router.push({ path: "/transactions/" + this.cardCode });
    },
    openLinkCardModal() {
      if (this.$store.getters.user == null) {
        this.isLoginModalActive = true;
        this.loginModalContentText =
          "Um Deine Karte verknüpfen zu können, musst Du Dich als erstes anmelden.";
        this.loginModalRedirectUrl = "/card/" + this.cardCode;
        return;
      }

      this.isLinkCardModalActive = true;
    },
    rechargeCard() {
      if (this.$store.getters.user == null) {
        this.isLoginModalActive = true;
        this.loginModalContentText =
          "Um Deine Karte aufladen zu können, musst Du Dich als erstes anmelden.";
        this.loginModalRedirectUrl = "/recharge/" + this.cardCode;
        return;
      }

      this.$router.push({ path: "/recharge/" + this.cardCode });
    },
    blockCard() {
      this.isLoading = true;

      this.$store.getters.axios
        .put(this.$store.getters.apiUrl + "/card/lock/" + this.cardCode)
        .then((response) => {
          this.card = response.data;
          this.qrCodeData =
            "https://feuerlöscher.digital/card/" + this.card.digital_card_code;

          this.$buefy.toast.open({
            message: "Deine Karte wurde erfolgreich gesperrt!",
            type: "is-success",
          });

          this.isBlockCardModalActive = false;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    linkCard() {
      this.isLoading = true;

      this.$store.getters.axios
        .put(this.$store.getters.apiUrl + "/card/link/" + this.cardCode)
        .then((response) => {
          if (response.status == 204) {
            this.notActivated = true;
            return;
          } else {
            this.notActivated = false;
          }

          this.card = response.data;
          this.qrCodeData =
            "https://feuerlöscher.digital/card/" + this.card.digital_card_code;

          this.$buefy.toast.open({
            message:
              "Deine Karte wurde erfolgreich mit deinem Kundenkonto verknüpft!",
            type: "is-success",
          });

          this.isLinkCardModalActive = false;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    activateCard() {
      this.isLoading = true;

      this.$store.getters.axios
        .put(this.$store.getters.apiUrl + "/card/activate/" + this.cardCode)
        .then((response) => {
          if (response.status == 204) {
            this.notActivated = true;
            return;
          } else {
            this.notActivated = false;
          }

          this.card = response.data;
          this.qrCodeData =
            "https://feuerlöscher.digital/card/" + this.card.digital_card_code;

          this.$buefy.toast.open({
            message: "Deine Karte wurde erfolgreich aktiviert!",
            type: "is-success",
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    loadCardDetails() {
      this.isLoading = true;

      this.$store.getters.axios
        .get(this.$store.getters.apiUrl + "/card/" + this.cardCode)
        .then((response) => {
          if (response.status == 204) {
            this.notActivated = true;
            return;
          } else {
            this.notActivated = false;
          }

          this.card = response.data;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style></style>
